import React from 'react'
import { DiamondButton } from '../../../../../components/ClipButton'


interface BoxBlockProps {
	img: string
	title: string
	descr: string
	color: string
}

const BoxBlock = ({
	img,
	title,
	descr,
	color
}: BoxBlockProps) => {
	return (
		<div className='h-[350px] w-[255px] my_md:w-full my_md:h-[460px] my_half_lg:h-[520px] lg:w-[372px] xl:w-full bg-[#1B1E20] flex flex-col rounded-3xl overflow-hidden relative z-30'>
			<div className="flex-1 flex flex-col items-center justify-between relative">

				<h3 className="mt-6 font-chakra leading-[128%] text-[25px] my_md:text-[32px] font-bold capitalize">{title}</h3>

				<div className="absolute top-1/2 left-0 right-0 -translate-y-1/2 z-20">
					<div
						style={{
							backgroundColor: color,
							boxShadow: `0px 0px 16px 0px ${color}`
						}}
						className=" w-[calc(100%-100px)] absolute top-[4px] z-20 left-1/2 -translate-x-1/2 h-[2px]"
					/>
					<div
						style={{
							textShadow: `0px 0px 16px #FFF600`,
							clipPath: 'polygon(0 0, calc(40px) 0, calc(50px) calc(10px), calc(100% - 50px) calc(10px), calc(100% - 40px) 0, 100% 0, 100% 100%, calc(100% - 40px) 100%, calc(100% - 50px) calc(100% - 10px), calc(50px) calc(100% - 10px), calc(40px) 100%, 0 100%)'
						}}
						className="py-[26px] px-4 bg-indigo/80 text-center font-chakra leading-[125%] font-medium text-xl my_md:text-2xl uppercase text-gold tracking-[0.1px]"
					>
						{descr}
					</div>
					<div
						style={{
							backgroundColor: color,
							boxShadow: `0px 0px 16px 0px ${color}`
						}}
						className=" w-[calc(100%-100px)] absolute bottom-[4px] z-20 left-1/2 -translate-x-1/2 h-[2px]"
					/>
				</div>

				<img src={img} alt="" className="w-[calc(255px-32px)] absolute bottom-8 my_md:bottom-0 aspect-auto-[308/373] my_md:w-[308px] -mb-28 my_md:-mb-11 z-10" />
				<div
					style={{
						backgroundColor: color,
					}}
					className="absolute blur-[64px] opacity-25 top-[150px] left-1/2 -translate-x-1/2 size-[445px] aspect-square"
				/>
			</div>

			<div className="relative z-20">
				<div
					style={{
						backgroundColor: color,
						boxShadow: `0px 0px 16px 0px ${color}`
					}}
					className=" w-[calc(100%-100px)] absolute top-[4px] z-20 left-1/2 -translate-x-1/2 h-[2px]"
				/>
				<div
					style={{
						clipPath: 'polygon(0 0, calc(40px) 0, calc(50px) calc(10px), calc(100% - 50px) calc(10px), calc(100% - 40px) 0, 100% 0, 100% 100%, 0 100%)',
					}}
					className="p-4 my_md:p-6 pt-[25px] my_md:pt-[34px] bg-indigo rounded-xl relative -mr-[2px] "
				>
					<DiamondButton bgColor='#061F36' className='mx-auto'>
						Get the pass
					</DiamondButton>
				</div>
			</div>

		</div>
	)
}

export default BoxBlock