import React, { useEffect, useState } from 'react'
import HexagonProps from '../../_types/hexagon'
import detectBrowser from '../../utils/detectBrowser';


const Hexagon = (

	{
		fillColor = "white",
		innerFill = "none",
		isSmall
	}: HexagonProps) => {
	const [browser, setBrowser] = useState<string>("");

	useEffect(() => {
		setBrowser(detectBrowser())
	}, [])

	return (
		<div className="flex flex-col items-center justify-center relative w-[2px] px-6 ml-px">
			<div
				style={{ backgroundColor: fillColor }}
				className="w-[2px] min-h-[2px] h-full -mb-px" />
			<div className="">

				<svg className='' width={isSmall ? "18" : "36"} height={isSmall ? "20" : "40"} viewBox="0 0 36 40" fill={innerFill} xmlns="http://www.w3.org/2000/svg">
					<path d="M26.1482 5.88277L26.1518 5.88481L33.5649 10.1445C34.0314 10.4126 34.3186 10.9101 34.3175 11.4482L34.3 19.9979L34.3 20.0021L34.3175 28.5518C34.3186 29.0899 34.0314 29.5874 33.5649 29.8555L26.1518 34.1152L26.1482 34.1172L18.7527 38.4073C18.2872 38.6773 17.7128 38.6773 17.2473 38.4073L9.85178 34.1172L9.84822 34.1152L2.43512 29.8555C1.96857 29.5874 1.68136 29.0899 1.68246 28.5518L1.70001 20.0021L1.7 19.9979L1.68246 11.4482C1.68136 10.9101 1.96857 10.4126 2.43513 10.1445L9.84822 5.88481L9.84823 5.88482L9.85177 5.88276L17.2473 1.59268C17.7128 1.32268 18.2872 1.32268 18.7527 1.59268L26.1482 5.88277Z" stroke={fillColor} strokeWidth={isSmall ? "4" : "2"} />
				</svg>
			</div>

			{!isSmall && <div
				style={{ backgroundColor: fillColor }}
				className="w-[2px] min-h-[2px] h-full -mt-px" />}

			{browser !== 'Safari' &&<div
				style={{
					backgroundColor: fillColor

				}}
				className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 size-[50px] aspect-square bg-white opacity-25 blur-lg"
			/>}
		</div>
	)
}

export const DoubleHexagon = ({
	isInnerHexagon = false,
	onClick
}: {
	isInnerHexagon?: boolean
	onClick?: () => void
}) => {
	return (
		<div onClick={onClick} className="relative size-10 aspect-square cursor-pointer">
			<svg className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' width="36" height="40" viewBox="0 0 36 40" fill={'none'} xmlns="http://www.w3.org/2000/svg">
				<path d="M26.1482 5.88277L26.1518 5.88481L33.5649 10.1445C34.0314 10.4126 34.3186 10.9101 34.3175 11.4482L34.3 19.9979L34.3 20.0021L34.3175 28.5518C34.3186 29.0899 34.0314 29.5874 33.5649 29.8555L26.1518 34.1152L26.1482 34.1172L18.7527 38.4073C18.2872 38.6773 17.7128 38.6773 17.2473 38.4073L9.85178 34.1172L9.84822 34.1152L2.43512 29.8555C1.96857 29.5874 1.68136 29.0899 1.68246 28.5518L1.70001 20.0021L1.7 19.9979L1.68246 11.4482C1.68136 10.9101 1.96857 10.4126 2.43513 10.1445L9.84822 5.88481L9.84823 5.88482L9.85177 5.88276L17.2473 1.59268C17.7128 1.32268 18.2872 1.32268 18.7527 1.59268L26.1482 5.88277Z" stroke={'#FFF600'} strokeWidth="2" />
			</svg>
			{isInnerHexagon && <svg className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' width="18" height="20" viewBox="0 0 36 40" fill={'#00FFD3'} xmlns="http://www.w3.org/2000/svg">
				<path d="M26.1482 5.88277L26.1518 5.88481L33.5649 10.1445C34.0314 10.4126 34.3186 10.9101 34.3175 11.4482L34.3 19.9979L34.3 20.0021L34.3175 28.5518C34.3186 29.0899 34.0314 29.5874 33.5649 29.8555L26.1518 34.1152L26.1482 34.1172L18.7527 38.4073C18.2872 38.6773 17.7128 38.6773 17.2473 38.4073L9.85178 34.1172L9.84822 34.1152L2.43512 29.8555C1.96857 29.5874 1.68136 29.0899 1.68246 28.5518L1.70001 20.0021L1.7 19.9979L1.68246 11.4482C1.68136 10.9101 1.96857 10.4126 2.43513 10.1445L9.84822 5.88481L9.84823 5.88482L9.85177 5.88276L17.2473 1.59268C17.7128 1.32268 18.2872 1.32268 18.7527 1.59268L26.1482 5.88277Z" stroke={'#00FFD3'} strokeWidth="2" />
			</svg>}
			{isInnerHexagon &&
				<div className="size-[50px] abs-center bg-[#00FFD3] blur-[20px] opacity-25 "></div>
			}
		</div>
	)
}

export default Hexagon