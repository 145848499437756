import React from 'react'
import cn from '../../../../../utils/cn'

const GridBlock = ({
	children,
	className,
}:{
	children?:React.ReactNode,
	className?:string
}) => {
	return (
		<div className={cn('p-4 rounded-2xl flex flex-col bg-indigo', className)}>
			{children}
		</div>
	)
}

export default GridBlock