import React from 'react'
import VerticalLine from '../../components/VerticalLine'
import SectionHeading from '../../components/SectionHeading'
import GradBorderContainer from '../../components/GradientBorderBlock'
import community_items from '../../_constants/community_items'
import Marquee from 'react-fast-marquee'
import { DiamondButton, ReadComicsDiamondButton } from '../../components/ClipButton'

const Community = () => {
	const windowWidth = window.innerWidth

	return (
		<section id='comics' className='container'>
			<VerticalLine className='h-40' color='linear-gradient(to bottom, #FF4DFF 0%, #EE4824 100%)' />
			<SectionHeading hexagonColor='#EE4824' subheadingText='Lore–based Sci–fi Story'>
				Community – Driven <span className="text-gold">Comics</span>
			</SectionHeading>
			<div className="flex h-24 my_sm:h-28 xl:h-20">
				<VerticalLine color='#EE4824' className='h-full' />
				<p className="text-grey ml-5 text-[15px] mt-5  my_md:text-2xl leading-[150%] tracking-[-0.48px] font-medium" >
					Step into a dystopian 21st–century world dominated by corporate giants and a fragile financial system.
				</p>
			</div>

			<GradBorderContainer direction='horizontal' gradColor='#EE4824' className='relative'>
				<div className="relative left-1/2 -translate-x-1/2 w-screen py-9 flex flex-col items-center justify-center">
					{windowWidth < 1024 ? null : <MiddleLineBg img_h={520} map_items={community_items} />}

					<Marquee
						gradient={false}
						speed={40}
						// pauseOnHover={true}
						// pauseOnClick={true}
						direction="left"
						className=''
					>
						{community_items.map(item => {
							return (
								<img key={item.id} src={item.img} alt="" className="h-[300px] my_md:h-[540px] relative z-20 mx-3" />
							)
						})}
					</Marquee>

					<div className="w-full px-5 my_md:px-0 block my_md:flex items-center justify-center">
						<ReadComicsDiamondButton className='whitespace-nowrap' containerClassName=' my_md:w-min relative z-40 mt-8'>
							read comics
						</ReadComicsDiamondButton>
					</div>

				</div>
			</GradBorderContainer>
		</section>
	)
}

export default Community


export const MiddleLineBg = ({ map_items, img_h = 520 }: {
	map_items: { img: string, id: number }[], img_h?: number
}) => {
	return (
		<>
			<div
				style={{
					width: (img_h - 40) + 'px',
					height: (img_h - 40) + 'px'
				}}
				className="size-[480px] absolute left-0 top-1/2 -translate-y-1/2 bg-[#59FFF9] rounded-full -translate-x-1/2" />
			<div
				style={{
					width: (img_h - 40) + 'px',
					height: (img_h - 40) + 'px'
				}}
				className="size-[480px] absolute right-0 top-1/2 -translate-y-1/2 bg-[#EE4925] rounded-full translate-x-1/2" />
			<div className="z-20 absolute top-0 left-0 right-0 bottom-0 "
				style={{
					boxShadow: "0px -16px 16px 0px #020F1B inset, 0px 16px 16px 0px #020F1B inset",
				}}
			></div>
			<div className="abs-center w-screen ">
				<Marquee
					gradient={false}
					speed={40}
					direction="left"
					className=''
				>
					{map_items.map(item => {
						return (
							<img
								style={{
									height: img_h + 'px'
								}}
								key={item.id + 'clone'} src={item.img} alt="" className="h-[540px] relative z-20 mx-3" />
						)
					})}
				</Marquee>
			</div>
			<div className="flex absolute top-0 left-0 right-0 bottom-0 ">
				{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25].map((index) => (
					<div
						key={index}
						style={{
							background: 'linear-gradient(270deg, rgba(255, 255, 255, 0.01) 0%, rgba(0, 0, 0, 0.20) 76.04%, rgba(255, 255, 255, 0.01) 100%)',
						}}
						className="flex-1 h-full backdrop-blur-[90px] bg-blend-overlay -mx-2 " />
				))}
			</div>
		</>
	)
}