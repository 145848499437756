import React from 'react'
import cn from '../../utils/cn'

const VerticalLine = ({
	color='white',
	className=''
}:{
	className?: string,
	color?: string
}) => {
	return (
		<div className={cn("px-6 h-full", className)}>
			<div 
				style={{
					background: color,
				}}
				className="w-[2px] h-full"/>
		</div>
	)
}

export default VerticalLine