import React from 'react'
import SectionHeading from '../../components/SectionHeading'

import bg from './assets/bg.svg'
import GameSection from './Sections/3.GameSection'
import ListSection from './Sections/2.ListSection'
import BoxesSection from './Sections/1.BoxesSection'
import EventsSection from './Sections/4.EventsSection'
import HexplanetSection from './Sections/5.HexplanetSection'

const Features = () => {
	return (
		<section className='container relative' id='battle_pass'>
			<SectionHeading className='' hexagonColor='#FFD600'>
				New Features with <span className="text-gold">Battle Pass</span>
			</SectionHeading>

			<BoxesSection />
			<BGUp />
			<ListSection />
			<GameSection />
			<EventsSection />
			<HexplanetSection />
		</section>
	)
}

const BGUp = () => {
	return (
		<div className="hidden xl:block absolute w-screen left-1/2 top-[350px] -translate-x-1/2 z-0">
			<img src={bg} alt="" className="" />
		</div>
	)
}



export default Features