import React, { useEffect, useState } from 'react'
import nav_items from '../../_constants/nav_items'
import { DiamondButton } from '../ClipButton'
import cn from '../../utils/cn'

const Navbar = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [displayBlock, setDisplayBlock] = useState(false)
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)
	const windowWidth = window.innerWidth

	useEffect(() => {
		let timeout: any;
	  
		if (windowWidth > 1280) {
		  setIsMenuOpen(false);
		}
	  
		if (isMenuOpen) {
		  document.body.style.overflow = 'hidden';
		} else {
		  document.body.style.overflow = 'auto';
	  
		  if (timeout) clearTimeout(timeout);
	  
		  timeout = setTimeout(() => {
			setDisplayBlock(false);
		  }, 400);
		}
	  
		return () => {
		  if (timeout) clearTimeout(timeout);
		};
	  }, [isMenuOpen, windowWidth]);

	const handleClick: React.MouseEventHandler = (e) => {
		if (e.target === e.currentTarget) {
			setIsMenuOpen(false)
		}
	}

	return (
		<nav className='px-[15px] py-[15px] my_md:py-4 my_md:px-10 flex items-center justify-between border-b border-indigo relative'>
			<ul className="hidden xl:flex py-4 justify-start gap-6 items-center">
				{
					nav_items.map(item => (
						<li key={item.id} className="">
							<a href={item.href} className="leading-[150%] font-bold text-white uppercase whitespace-nowrap">
								[ {item.name} ]
							</a>
						</li>
					))
				}
			</ul>

			<div onClick={() => { setIsMenuOpen(!isMenuOpen); setDisplayBlock(true) }} className="flex flex-col z-50 xl:hidden rounded-2xl bg-indigo size-14 aspect-square items-center justify-center gap-[5px] cursor-pointer">
				<div className="w-6 h-[3px] bg-white rounded-[1px]"></div>
				<div className="w-6 h-[3px] bg-white rounded-[1px]"></div>
				<div className="w-6 h-[3px] bg-white rounded-[1px]"></div>
			</div>

			<div
				onClick={handleClick}
				className={cn("fixed block xl:hidden top-0 right-0 bottom-0 left-0 bg-dark_blue/60 z-40 backdrop-blur-sm transition-opacity duration-300", isMenuOpen ? "opacity-100" : "opacity-0", displayBlock ? 'top-0' : 'top-full')}>
				<div className={cn("absolute top-0 right-0 bottom-0 bg-indigo px-20 flex items-center justify-center transition-transform duration-300", isMenuOpen ? "translate-x-0" : "translate-x-full")}>
					<ul className="flex flex-col py-4 justify-start gap-6 items-center">
						{
							nav_items.map(item => (
								<li onClick={() => setIsMenuOpen(false)} key={item.id} className="">
									<a href={item.href} className="leading-[150%] text-[24px] font-bold text-white uppercase whitespace-nowrap">
										[ {item.name} ]
									</a>
								</li>
							))
						}
					</ul>
				</div>
			</div>


				<DiamondButton className='text-sm my_md:text-base' containerClassName='w-auto'>
					Launch App
				</DiamondButton>
		</nav>
	)
}

export default Navbar