import React, { useState } from 'react'
import VerticalLine from '../../components/VerticalLine'
import SectionHeading from '../../components/SectionHeading'

import up_quote from './assets/quotes/up_quote.svg'
import bottom_quote from './assets/quotes/bottom_quote.svg'
import FAQ_items from '../../_constants/FAQ_items'
import { FAQDiamondButton } from '../../components/ClipButton'
import Hexagon from '../../components/Hexagon'

const FAQ = () => {
	const [isAllShown, setIsAllShown] = useState(false)
	const handleClick = () => {
		console.log("click");
		setIsAllShown(true);
	};

	return (
		<section className='container pb-20' id='faq'>
			<VerticalLine color='linear-gradient(to bottom, #FFF600 0%, #25A2E0 100%)' className='h-40' />
			<SectionHeading subheadingText='Get an answer to your question' hexagonColor='#25A2E0' >
				FAQ
			</SectionHeading>

			<div className="flex flex-col gap-10 border-l-[2px] border-[#25A2E0] py-10 ml-6">
				{FAQ_items.map((item, index) => {
					const max_index = 2
						if (index > max_index && !isAllShown)
							return null

					return(
						<FAQBlock key={index} {...item} isGradient={index === max_index && !isAllShown}/>
					)
				})}
			</div>

			<div className="flex items-center justify-between">
				<VerticalLine className='h-16' color='#25A2E0' />
				{!isAllShown && <FAQDiamondButton
					strokeColor='#FFF600' 
					className='text-[#FFF600]' 
					containerClassName="w-auto"
					onClick={handleClick}
				>
					Show all faq
				</FAQDiamondButton>}
			</div>
			<Hexagon isSmall fillColor='#25A2E0' />
		</section>
	)
}

const FAQBlock = ({ question, answer, end_link, isGradient }:{
	question: string,
	answer: string,
	end_link?: string
	isGradient?: boolean
}) => {

	return(
		<div className="p-5 pl-[45px] bg-gradient-to-r from-indigo to-indigo/0 relative">
			<h3 className="mb-[10px] text-[20px] my_md:text-2xl font-medium leading-[128%] ">{question}</h3>

			<p className="text-[15px] my_md:text-base leading-normal tracking-[-2%] font-medium relative text-grey">
				<img src={up_quote} alt="" className="absolute top-0 -left-4" />
				{answer} {end_link && <a href={end_link} className="text-diamond underline">{end_link}</a>}
				<img src={bottom_quote} alt="" className="absolute bottom-0 -right-4 " />
			</p>
			{isGradient && <div className="absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-t from-dark_blue via-dark_blue/60 to-dark_blue/0"/>}
		</div>
	)
}
export default FAQ