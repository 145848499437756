import GradBorderContainer from "../../../../components/GradientBorderBlock"
import feature_box_items from '../../../../_constants/feature_box_items'
import coin_img from '../../assets/coin/coin_group.png'
import VerticalLine from "../../../../components/VerticalLine"
import BoxBlock from "./BoxBlock"


const BoxesSection = () => {
	const windowWidth = window.innerWidth

	return (
		<div className="relative z-40">
			<VerticalLine className='h-10' color='#FFD600' />

			<GradBorderContainer className='xl:px-10 py-10 overflow-scroll my_md:overflow-visible w-screen left-1/2 -translate-x-1/2  my_md:w-auto my_md:flex items-center justify-center gap-10 relative z-0' gradColor='#FFD600' direction='horizontal'>
			{/* <GradBorderContainer className='xl:px-10 py-10 overflow-visible left-1/2 -translate-x-1/2 w-auto flex items-center justify-center gap-10 relative z-0' gradColor='#FFD600' direction='horizontal'> */}

				<img src={coin_img} alt="flying coin" className='absolute z-10 bottom-1/2 -left-24 rotate-180 scale-y-[-1] w-[180px] translate-y-1/4' />
					{windowWidth > 1280 && feature_box_items.map((item, index) => (

						<BoxBlock key={index} {...item} />
					))}
					
					<div className="w-max my_md:w-full lg:w-max flex justify-center gap-2 my_md:gap-5 lg:gap-6 xl:hidden">
					{feature_box_items.map((item, index) => (

						<BoxBlock key={index} {...item} />
					))}
				</div>
				
				<img src={coin_img} alt="flying coin" className='absolute bottom-0 -right-12 w-[130px]' />
			</GradBorderContainer>
		</div>
	)
}

export default BoxesSection
