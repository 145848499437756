import React from 'react';
import Navbar from './components/Navbar';
import Hero from './page/1.Hero';
import Partners from './page/2.Partners';
import Features from './page/3.Features';
import Community from './page/4.Community';
import Assets from './page/5.Assets';
import Collection from './page/6.Collection';
import FAQ from './page/7.FAQ';
import Footer from './components/Footer';

function App() {
  return (
    <div className="bg-dark_blue min-h-screen text-white overflow-x-hidden font-chakra overflow-hidden">
      <Navbar />
      
      <Hero />
      <Partners />
      <Features />
      <Community />
      <Assets />
      <Collection />
      <FAQ /> 

      <Footer />
    </div>
  );
}

export default App;
