import React from 'react'
import SectionHeading from '../../components/SectionHeading'
import GradBorderContainer from '../../components/GradientBorderBlock'
import Marquee from 'react-fast-marquee'
import MarqueeBlock from './MarqueeBlock'
import partner_items from '../../_constants/partners_items'
import VerticalLine from '../../components/VerticalLine'
import scale_logo from '../1.Hero/assets/scale.svg'

const Partners = () => {
	const windowWidth = window.innerWidth
	
	return (
		<section className='container' id='partners'>
			<SectionHeading>
				Our Partners
			</SectionHeading>
			<VerticalLine className='h-10' color='#fff' />


			<GradBorderContainer direction='horizontal' className=''>
				<div className="w-screen relative left-1/2 -translate-x-1/2">
				<Marquee
					gradient={false}
					speed={40}
					// pauseOnHover={true}
					// pauseOnClick={true}
					direction="left"
					className='pt-10 xl:py-10 mb-4 xl:mb-0'
				>
					{partner_items.map((item, index) => {
						if (windowWidth < 1280 && index > partner_items.length / 2) return null
					
						return (<MarqueeBlock key={index}>
							<img src={item.img} alt="" className="" />
						</MarqueeBlock>)
					})}
				</Marquee>
				{windowWidth < 1280 && <Marquee
					gradient={false}
					speed={40}
					// pauseOnHover={true}
					// pauseOnClick={true}
					direction="right"
					className='block pb-10 xl:hidden xl:py-10'
				>
					{partner_items.slice(
						Math.ceil(partner_items.length / 2),
						
					).map((item, index) => (
						<MarqueeBlock key={index}>
							<img src={item.img} alt="" className="" />
						</MarqueeBlock>
					))}
				</Marquee>}
				</div>
			</GradBorderContainer>
			<div className="flex justify-between items-start ">
				<VerticalLine className='h-40' color='linear-gradient(to bottom, #fff 0%, #FFD600 100%)' />
				<img src={scale_logo} alt="" className="block my_sm:hidden mt-5" />
			</div>
		</section>
	)
}



export default Partners