import img1 from '../page/5.Assets/assets/lorebooks/lorebook_1.png'
import img2 from '../page/5.Assets/assets/lorebooks/lorebook_2.png'
import img3 from '../page/5.Assets/assets/lorebooks/lorebook_3.png'
import img4 from '../page/5.Assets/assets/lorebooks/lorebook_4.png'
const lorebook_items = [
	{
		id: 0,
		img: img1
	},
	{
		id: 1,
		img: img2
	},
	{
		id: 2,
		img: img3
	},
	{
		id: 3,
		img: img4
	},
]

export default lorebook_items;