import React from 'react'
import Artbook from './1.Artbook'
import Lorebook from './2.Lorebook'
import Soundtrack from './3.Soundtrack'

const Assets = () => {
	return (
		<section className='container'>
			<Artbook />
			<Lorebook />
			<Soundtrack />

		</section>
	)
}

export default Assets