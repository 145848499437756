import React from 'react'
import logo_img from './assets/logo.svg'
import cn from '../../utils/cn'
const Logo = ({className}:{className?:string}) => {
	return (
		<div className={cn('relative w-[192px]', className)}>
			<div className="absolute rounded-full bg-[#1ED8C1] w-full h-full top-0 left-0 z-0 blur-[39.9px] opacity-25"></div>
			<img src={logo_img} alt="" className="relative z-10" />
		</div>
	)
}

export default Logo