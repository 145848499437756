import hero_built_items from "../../../../_constants/hero_build_items"
import VerticalLine from "../../../../components/VerticalLine"
import BoxImage from "./BoxImage"

const ListSection = () => {
	const listItems = [
		'Every Battle Pass has Node Functionality',
		'Multiple classes: Gold, Platinum, Diamond, Carbon',
		'Perks, Bonuses, Hyperfarming Boosts and more',
	]

	return (
		<>
			<VerticalLine className='h-10' color='#FFD600' />

			<div
				style={{
					background: 'linear-gradient(to right,  rgba(255, 214, 0, .2) 0%, rgba(255, 214, 0, 0) 100%)',
				}}
				className="border-l-[2px] flex items-center gap-8 my_md:px-6 border-[#ffd600] h-[260px] my_md:h-[360px] ml-6 relative z-0 overflow-hidden"
			>
				<BoxImage />

				<ul className="py-6 font-chakra space-y-4 relative z-40">
					{listItems.map((item, index) => (
						<li key={index} className='flex items-center justify-start gap-2 leading-[128%] capitalize font-medium text-[20px] my_md:text-[32px]'>
							<div className="size-2 rounded-full bg-white" />{item}
						</li>
					))}
				</ul>
			</div>

			<div className="h-[55px] flex justify-between my_md:hidden">
				<VerticalLine color="#ffd600" />
				<div className="flex gap-3 items-center justify-center pt-5">
					<span className="text-grey text-[15px] leading-[150%] font-medium font-chakra ">
						Built On
					</span>

					<div className="flex items-center justify-center gap-2">
						{
							hero_built_items.map(item =>
								<img key={item.id} src={item.img} alt="" className="" />
							)
						}
					</div>
				</div>

			</div>
		</>
	)
}

export default ListSection