import React from 'react'
import footer_items from '../../_constants/footer_items'

const Footer = () => {
	return (
		<footer className='border-t border-indigo'>
			<div className="my_md:px-10 py-6 flex flex-col my_md:flex-row items-start my_md:items-center justify-between">
				<div className="border-b border-indigo my_md:border-b-0 pb-5 my_md:pb-0 px-5 my_md:px-0 w-full my_md:w-auto">
					<div className="flex items-center justify-between my_md:items-end my_md:justify-center gap-4">
						<div className="flex flex-col">
							<p className="text-grey leading-normal text-base font-medium">Brand Assets</p>
							<p className="font-medium text-2xl leading-[128%]">Media Kit</p>
						</div>

						<p className="text-gold font-bold leading-normal uppercase text-base mt-2 cursor-pointer">[ Download ]</p>
					</div>
				</div>

				<div className="flex items-center my_md:justify-center gap-4 pt-5 my_md:pt-0 px-5 my_md:px-0">
					{footer_items.map(item => (
						<a key={item.id} href={item.href} className="rounded-2xl cursor-pointer bg-indigo size-14 aspect-square flex items-center justify-center">
							<img src={item.icon} alt="" className="" />
						</a>
					))}
				</div>
			</div>
			<div className="text-left my_md:text-center py-4 px-5 my_md:px-10 text-grey leading-normal tracking-[-2%] border-t border-indigo">
				<a href="/Terms.pdf" className="underline" target="_blank" rel="noopener noreferrer">Terms</a> and <a href="/Privacy.pdf" className="underline" target="_blank" rel="noopener noreferrer">conditions</a> privacy policy
			</div>
		</footer>
	)
}

export default Footer