import icon1 from '../page/1.Hero/assets/built_icons/icon_1.svg'
import icon2 from '../page/1.Hero/assets/built_icons/icon_2.svg'
import icon3 from '../page/1.Hero/assets/built_icons/icon_3.svg'
import icon4 from '../page/1.Hero/assets/built_icons/icon_4.svg'
import icon5 from '../page/1.Hero/assets/built_icons/icon_5.svg'

const hero_built_items = [
	{
		id: 0,
		img: icon1,
	},
	{
		id: 1,
		img: icon2,
	},
	{
		id: 2,
		img: icon3,
	},
	{
		id: 3,
		img: icon4,
	},
	{
		id: 4,
		img: icon5,
	},
]

export default hero_built_items