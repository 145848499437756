import icon_1 from '../components/Footer/assets/icon_1.svg'
import icon_2 from '../components/Footer/assets/icon_2.svg'
import icon_3 from '../components/Footer/assets/icon_3.svg'
import icon_4 from '../components/Footer/assets/icon_4.svg'

const footer_items = [
	{
		id: 0,
		icon: icon_1,
		href: 'https://twitter.com/universesatoshi'
	},
	{
		id: 1,
		icon: icon_2,
		href: 'https://www.youtube.com/@Satoshi_Universe'
	},
	{
		id: 2,
		icon: icon_3,
		href: 'https://discord.gg/satoshiuniverse'
	},
	{
		id: 3,
		icon: icon_4,
		href: 'https://medium.com/satoshiquest'
	},
]

export default footer_items;