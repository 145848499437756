import React from 'react'
import { HexplanetGooglePlayBtn } from '../../../../components/ClipButton'
import GradBorderContainer from '../../../../components/GradientBorderBlock'
import VerticalLine from '../../../../components/VerticalLine'

import game_icon_1 from '../../assets/game/icon2.svg'
import phone_img from '../../assets/game/phone2.png'
import disc_img from '../../assets/game/disc.png'
import coin_img from '../../assets/coin/coin_group.png'

const HexplanetSection = () => {
	return (
		<div className=''>
			<VerticalLine className='h-40 relative' color='linear-gradient(to bottom, #FFD600 0%, #FF4DFF 100%)' />

			<GradBorderContainer
				direction='vertical' gradColor='#FF4DFF'
				className='p-5 my_md:p-10 flex flex-col-reverse lg:flex-row gap-5 my_md:gap-10 items-center justify-center relative '
			>
				<img src={disc_img} alt="flying coin" className='absolute z-20 top-0 right-2 translate-x-1/2 -translate-y-1/2 w-[100px] my_md:w-[160px]' />
				<img src={coin_img} alt="flying coin" className='absolute z-50 -bottom-8 -left-7 my_md:-bottom-12 my_md:-left-14 xl:bottom-0 xl:-left-24 rotate-180 scale-y-[-1] w-[100px] my_md:w-[180px] translate-y-1/4' />

				<div className="flex-1 flex flex-col gap-8 relative z-40">
					<div className="items-center justify-start gap-8 hidden lg:flex">
						<img src={game_icon_1} alt="" className="" />
						<h3 className="text-5xl leading-none font-chakra font-bold">Hex <br />Planet</h3>
					</div>

					<div className="">
						<p className="text-grey text-center my_md:text-left text-[20px] my_md:text-[32px] leading-[150%] font-medium tracking-[-0.64px] font-chakra ">
							City Builder is the ultimate mobile cyberpunk game where players can build a virtual business empire and interact in a 3D-voxel open-world
						</p>

					</div>

					<div className="flex justify-center items-center gap-6 w-full">
						<HexplanetGooglePlayBtn containerClassName="flex-1" className="flex items-center justify-center" />
					</div>
				</div>

				<div className="flex-1 relative z-20">
					<div className="flex items-center justify-center lg:hidden  gap-8">
						<img src={game_icon_1} alt="" className="size-20 my_md:size-auto" />
						<h3 className="text-[25px] my_md:text-5xl leading-none font-chakra font-bold">Hex <br />Planet</h3>
					</div>
					<img src={phone_img} alt="" className="w-[calc(100vw-2rem)] my_md:w-[calc(105%)] max-w-none -mx-6 -mt-6 -mb-12 my_md:-mt-20 my_md:-mb-28 xl:mt-0 xl:mb-0" />
				</div>
			</GradBorderContainer>
		</div>
	)
}

export default HexplanetSection