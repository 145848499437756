import React from 'react'
import VerticalLine from '../../components/VerticalLine'
import SectionHeading from '../../components/SectionHeading'
import GradBorderContainer from '../../components/GradientBorderBlock'
import { MiddleLineBg } from '../4.Community'
import Marquee from 'react-fast-marquee'
import ClipButtonBase, { ClipButtonBaseReverse } from '../../components/ClipButton'
import collection_items from '../../_constants/collection_items'

import btn_img_1 from './assets/btn_img_1.svg'
import btn_img_2 from './assets/btn_img_2.svg'
import btn_img_3 from './assets/btn_img_3.svg'
import btn_img_4 from './assets/btn_img_4.svg'

const Collection = () => {
	const windowWidth = window.innerWidth
	return (
		<section id='nft' className='container relative z-20'>
			<VerticalLine color='linear-gradient(to bottom, #00FFD3 0%, #FFF600 100%)' className='h-40' />
			<SectionHeading subheadingText='Nft Card Collection' hexagonColor='#FFF600' >
				SATOSHI QUEST
			</SectionHeading>
			<div className="flex h-28 items-center ">
				<VerticalLine color='#FFF600' className='h-full' />
				<p className="text-grey ml-5 text-[15px] my_md:text-2xl leading-[150%] tracking-[-0.48px] font-medium" >
					Each collectible Satoshi’s Quest trading card features an exclusiveartwork depicting one of our four heroes (the Hacker, the Slacker, the Geek or the Freak).
				</p>
			</div>

			<GradBorderContainer direction='horizontal' gradColor='#FFF600' className='relative'>
				<div className="relative left-1/2 -translate-x-1/2 w-screen py-5 my_md:py-9 flex flex-col items-center justify-center">

					{windowWidth > 768 &&
						<MiddleLineBg map_items={collection_items} img_h={320} />}

					<Marquee
						gradient={false}
						speed={40}
						direction="left"
						className='mb-[10px] my_md:mb-0'
					>
						{collection_items.map((item, index) => {
							if (windowWidth < 1280 && index > collection_items.length / 2) return null

							return (
								<img key={item.id} src={item.img} alt="" className="h-[140px] my_md:h-[320px] relative z-20 mx-[5px] my_md:mx-3" />
							)
						})}
					</Marquee>

					{windowWidth < 768 && <Marquee
						gradient={false}
						speed={40}
						pauseOnHover={true}
						pauseOnClick={true}
						direction="left"
						className='block xl:hidden xl:py-10'
					>
						{collection_items.slice(
							Math.ceil(collection_items.length / 2),

						).map((item, index) => (
							<img key={item.id} src={item.img} alt="" className="h-[140px] my_md:h-[320px] relative z-20 mx-[5px] my_md:mx-3" />

						))}
					</Marquee>}

					<div
						style={{
							background: 'linear-gradient(to top, #020F1B 0%, rgba(2, 15, 27, 0) 100%)',
						}}
						className="flex flex-col items-center justify-center w-full relative mt-5 my_md:mt-8 "
					>
						<h5 className="text-grey font-medium leading-normal tracking-[-0.32px] text-base mb-4">Find out on</h5>

						<div className="grid grid-cols-2 gap-4 container px-5 my_md:px-20 xl:px-0 relative z-20">
							<ClipButtonBaseReverse
								strokeColor='linear-gradient(to right, #29F6A8 0%, #76FF0B 50%, #FFFFFF 100%)'
								className='py-[10px]'
								onClick={() => window.open('https://element.market/collections/satoshiquest?search%5Btoggles%5D[0]=BUY_NOW', '_blank')}
								>
								<img src={btn_img_1} alt="" className="mx-auto" />
							</ClipButtonBaseReverse>
							<ClipButtonBase
								strokeColor='linear-gradient(to right, #E21280 0%, #FF4FAC 50%, #FFFFFF 100%)'
								className='py-[10px]'
								onClick={() => window.open('https://www.bilinear.io/collections/linea/0x15f0bf5ff3f5aa1a3ff10120c1da669b80309642', '_blank')}
								>
								<img src={btn_img_2} alt="" className="mx-auto" />
							</ClipButtonBase>
							<ClipButtonBase
								strokeColor='linear-gradient(to right, #C2C2C2 0%, #FFFFFF 100%)'
								className='py-[10px]'
								onClick={() => window.open('https://www.okx.com/web3/marketplace/nft/collection/linea/satoshiquest', '_blank')}
							>
								<img src={btn_img_3} alt="" className="mx-auto" />
							</ClipButtonBase>
							<ClipButtonBaseReverse
								strokeColor='linear-gradient(to right, #7257FF 0%, #7960FF 50%, #FFFFFF 100%)'
								className='py-[10px]'
								onClick={() => window.open('https://alienswap.xyz/collection/linea/satoshi-quest-52d7', '_blank')}
							>
								<img src={btn_img_4} alt="" className="mx-auto" />
							</ClipButtonBaseReverse>
						</div>
					</div>

				</div>
			</GradBorderContainer>
		</section>
	)
}

export default Collection