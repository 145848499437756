import img1 from '../page/6.Collection/assets/nft/nft_1.png'
import img2 from '../page/6.Collection/assets/nft/nft_2.png'
import img3 from '../page/6.Collection/assets/nft/nft_3.png'
import img4 from '../page/6.Collection/assets/nft/nft_4.png'
import img5 from '../page/6.Collection/assets/nft/nft_5.png'
import img6 from '../page/6.Collection/assets/nft/nft_6.png'
import img7 from '../page/6.Collection/assets/nft/nft_7.png'
import img8 from '../page/6.Collection/assets/nft/nft_8.png'
import img9 from '../page/6.Collection/assets/nft/nft_9.png'
import img10 from '../page/6.Collection/assets/nft/nft_10.png'
import img11 from '../page/6.Collection/assets/nft/nft_11.png'
import img12 from '../page/6.Collection/assets/nft/nft_12.png'

const collection_items = [
	{
		id: 0,
		img: img1
	},
	{
		id: 1,
		img: img2
	},
	{
		id: 2,
		img: img3
	},
	{
		id: 3,
		img: img4
	},
	{
		id: 4,
		img: img5
	},
	{
		id: 5,
		img: img6
	},
	{
		id: 6,
		img: img7
	},
	{
		id: 7,
		img: img8
	},
	{
		id: 8,
		img: img9
	},
	{
		id: 9,
		img: img10
	},
	{
		id: 10,
		img: img11
	},
	{
		id: 11,
		img: img12
	},
]

export default collection_items