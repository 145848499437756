import img1 from '../page/4.Community/assets/comics_1.png'
import img2 from '../page/4.Community/assets/comics_2.png'
import img3 from '../page/4.Community/assets/comics_3.png'
import img4 from '../page/4.Community/assets/comics_4.png'
import img5 from '../page/4.Community/assets/comics_5.png'
import img6 from '../page/4.Community/assets/comics_6.png'
import img7 from '../page/4.Community/assets/comics_7.png'
import img8 from '../page/4.Community/assets/comics_8.png'
import img9 from '../page/4.Community/assets/comics_9.png'
import img10 from '../page/4.Community/assets/comics_10.png'

const community_items = [
	{
		id:0,
		img: img1,
	},
	{
		id:1,
		img: img2,
	},
	{
		id:2,
		img: img3,
	},
	{
		id:3,
		img: img4,
	},
	{
		id:4,
		img: img5,
	},
	{
		id:5,
		img: img6,
	},
	{
		id:6,
		img: img7,
	},
	{
		id:7,
		img: img8,
	},
	{
		id:8,
		img: img9,
	},
	{
		id:9,
		img: img10,
	},
]

export default community_items