import img1 from '../page/2.Partners/assets/img_1.svg'
import img2 from '../page/2.Partners/assets/img_2.svg'
import img3 from '../page/2.Partners/assets/img_3.svg'
import img4 from '../page/2.Partners/assets/img_4.svg'
import img5 from '../page/2.Partners/assets/img_5.svg'
import img6 from '../page/2.Partners/assets/img_6.svg'
import img7 from '../page/2.Partners/assets/img_7.svg'
import img8 from '../page/2.Partners/assets/img_8.svg'
import img9 from '../page/2.Partners/assets/img_9.svg'

const partner_items = [
	{
		id: 0,
		img: img1,
	},
	{
		id: 1,
		img: img2,
	},
	{
		id: 2,
		img: img3,
	},
	{
		id: 3,
		img: img4,
	},
	{
		id: 4,
		img: img5,
	},
	{
		id: 5,
		img: img6,
	},
	{
		id: 6,
		img: img7,
	},
	{
		id: 7,
		img: img8,
	},
	{
		id: 8,
		img: img9,
	},

	// additional
	{
		id: 10,
		img: img1,
	},
	{
		id: 11,
		img: img2,
	},
	{
		id: 12,
		img: img3,
	},
	{
		id: 13,
		img: img4,
	},
]

export default partner_items