import React from 'react'
import cn from '../../utils/cn'
import GradBorderContainerProps from '../../_types/grad_block'



const GradBorderContainer = ({
	children,
	className="",
	gradColor = "rgb(255, 255, 255)",
	direction = 'vertical',
	mode="full",
	classNameContainer=''
}: GradBorderContainerProps) => {

	return (
		<div
			style={direction === 'horizontal' ? {
				background: `linear-gradient(to right, rgba(2, 15, 27,0) 0%, ${gradColor} 2%, ${gradColor} 98%, rgba(2, 15, 27,0) 100%)`,
				padding: "2px 0",
				borderRadius: "0px"
			} : {
				background: `linear-gradient(to bottom, ${mode === 'full' ?gradColor:'rgba(2, 15, 27,0)'} 0%, rgba(2, 15, 27,0) 25%, rgba(2, 15, 27,0) 75%, ${gradColor} 100%)`,
				padding: "2px",
				borderRadius: "24px"
			}}
			className={classNameContainer}
		>
			<div
				className={cn('bg-dark_blue', direction === "horizontal" ? "" : " rounded-[23px] ", className)}>
				{children}
			</div>
		</div>
	)
}

export default GradBorderContainer