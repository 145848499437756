import React, { useEffect, useState } from 'react';
import VerticalLine from '../../../../components/VerticalLine';
import { DoubleHexagon } from '../../../../components/Hexagon';

import scroll from '../../assets/events/scroll.png';
import okx from '../../assets/events/okx.png';
import linea from '../../assets/events/linea.png';
import tonx from '../../assets/events/tonx.png';
import bg from '../../assets/bg2.svg';

import cn from '../../../../utils/cn';

const EventsSection = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [fade, setFade] = useState(false);
	const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
	const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

	const event_items = [
		{
			id: 0,
			subtitle: 'Events',
			title: 'Run, Linea, Run',
			description: `🚀 The Runner2060 playtest is now live on Google Play and AppStore, and we're launching our 4-week "Run, Linea, Run!" Campaign with lots of partners!`,
			img: linea,
			link: "https://x.com/universesatoshi/status/1805522030210502883?s=46&t=x0DE3ICwGQfIrFbG7xfYew",
		},
		{
			id: 1,
			subtitle: 'Events',
			title: 'Run, OKX, Run',
			description: `🚀 We're kicking off another exciting new Runner event — Run, OKX, Run! \n
			⚡️ We're building in the @XLayerOfficial ecosystem, and we want you to be part of the action.`,
			img: okx,
			link: "https://x.com/universesatoshi/status/1828791811512357319?s=46&t=x0DE3ICwGQfIrFbG7xfYew",
		},
		{
			id: 2,
			subtitle: 'Events',
			title: 'Run, Scroll, Run',
			description: `🚀 We're back with another epic event in the Web3 space — Run, Scroll, Run! \n
			⚡️ As we starting to build in this ecosystem, it’s time for you to join the action.`,
			img: scroll,
			link: "https://x.com/universesatoshi/status/1825489852076286221?s=46&t=x0DE3ICwGQfIrFbG7xfYew",
		},
		{
			id: 3,
			subtitle: 'Events',
			title: 'Run, TonX, Run',
			description: `🚀 We're excited to bring a special challenge to Hack Seasons in Singapore — and it's your chance to win big!`,
			img: tonx,
		},
	];

	const handleNextProject = () => {
		setFade(true);
		setTimerId(
			setTimeout(() => {
				setActiveIndex((prevIndex) => (prevIndex + 1) % event_items.length);
			}, 400)
		);
		setTimeout(() => {
			setFade(false);
		}, 600);
	};

	const handleClick = (index: number) => {
		setFade(true);
		clearTimeout(timerId!);
		clearInterval(intervalId!);

		setTimerId(
			setTimeout(() => {
				setActiveIndex(index);
			}, 400)
		);
		setTimeout(() => {
			setFade(false);
		}, 600);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			handleNextProject();
		}, 3000);

		setIntervalId(interval);

		return () => clearInterval(interval);
	}, []);

	return (
		<section id='events' className='relative'>
			<VerticalLine className='h-40 relative' color='linear-gradient(to bottom, #994BFF 0%, #FFD600 100%)' />

			<MiddleLine className='block absolute left-6 top-[35%] my_md:hidden' />
			<div className='ml-6 border-l-[2px] border-[#ffd600] flex items-center justify-center relative' onClick={() => {
					const currentItem = event_items[activeIndex % event_items.length];
					if (currentItem.link) {
						window.open(currentItem.link, '_blank');
					}
				}}>
				<MiddleLine className='hidden my_md:block' />
				<div className='flex flex-col gap-6 pl-[45px] my_md:gap-16' >
					<div className={'flex flex-col my_md:flex-row gap-10 transition-opacity ' + (fade ? 'opacity-0' : 'opacity-100')}>
						<div className='flex-1 flex flex-col items-start justify-center' >
							<h4 className='text-grey text-[20px] lg:text-[32px] font-medium leading-[128%] capitalize font-chakra'>
								{event_items[activeIndex % event_items.length]?.subtitle}
							</h4>
							<h3 className='text-gold text-[35px] lg:text-[48px] font-bold leading-[128%] capitalize'>
								{event_items[activeIndex % event_items.length]?.title}
							</h3>
							<p className='text-[20px] lg:text-[32px] font-medium leading-[150%]' style={{ whiteSpace: 'pre-line' }}>
								{event_items[activeIndex % event_items.length]?.description}
							</p>
						</div>

						<div className='flex-1 relative'>
							<img src={event_items[activeIndex % event_items.length]?.img} alt='' className='relative z-10' />
							<img
								src={event_items[activeIndex % event_items.length]?.img}
								alt=''
								className='absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 blur-[20px]'
							/>
						</div>
					</div>

					<div className='flex gap-4 items-center justify-center scale-50 my_md:scale-100 relative z-50'>
						{[...Array(event_items.length)].map((_, index) => (
							<DoubleHexagon onClick={() => handleClick(index)} key={index} isInnerHexagon={activeIndex === index} />
						))}
					</div>
				</div>
				<div className='hidden abs-center z-20 w-screen xl:flex items-end justify-end pt-[800px]'>
					<img src={bg} alt='' />
				</div>
			</div>
		</section>
	);
};

const MiddleLine = ({ className }: { className?: string }) => {
	return (
		<div className={cn('flex w-[24px] items-center justify-center', className)}>
			<div className='w-[24px] h-[2px] bg-gradient-to-r from-[#ffd600] to-[rgba(255,214,0,0)] relative'>
				<div className='size-[50px] aspect-square bg-[#ffd600] blur-[20px] absolute top-1/2 -translate-y-1/2 left-0 -translate-x-1/2 opacity-25' />
			</div>
		</div>
	);
};

export default EventsSection;