// [ Partners ]
// [ battle pass ]
// [ games ]
// [ Events ]
// [ Comics ]
// [ Artbook ]
// [ soundtrack ]
// [ nft ]
// [ faq ]

const nav_items = [
	{
		id: 0,
		name: 'Partners',
		href: '#partners',
	},
	{
		id: 1,
		name: 'battle pass',
		href: '#battle_pass',
	},
	{
		id: 2,
		name: 'games',
		href: '#games',
	},
	{
		id: 3,
		name: 'Events',
		href: '#events',
	},
	{
		id: 4,
		name: 'Comics',
		href: '#comics',
	},
	{
		id: 5,
		name: 'Artbook',
		href: '#artbook',
	},
	{
		id: 6,
		name: 'soundtrack',
		href: '#soundtrack',
	},
	{
		id: 7,
		name: 'nft',
		href: '#nft',
	},
	{
		id: 8,
		name: 'faq',
		href: '#faq',
	}
]

export default nav_items;