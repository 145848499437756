import React from 'react'

const SocialButton = ({
	color = 'white',
	icon = '',
	href = '',
	text = ''
}: {
	color: string
	icon: string
	href: string
	text: string
}) => {
	return (
		<a
			style={{
				borderColor: color,
			}}
			className='flex flex-1 gap-2 my_sm:gap-4 items-center justify-center border-[2px] rounded-xl py-[10px] px-[10px] my_md:px-4' href={href} target='_blank' rel="noreferrer">
			<div className="relative">
				<div className="size-[30px] aspect-square">
					<img src={icon} alt="" className="" />
				</div>
				<div
					style={{
						background: color,
					}}
					className="opacity-25 size-[56px] aspect-square absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 blur-[20px]" />
			</div>

			<span className="underline font-bold text-[12px] my_sm:text-[14px] my_md:text-base  font-chakra leading-[150%]" style={{
				color: color,
			}}>
				{text}
			</span>
		</a>
	)
}

export default SocialButton