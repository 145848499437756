import box_img_1 from '../page/3.Features/assets/img_1.png';
import box_img_2 from '../page/3.Features/assets/img_2.png';
import box_img_3 from '../page/3.Features/assets/img_3.png';

const feature_box_items = [
	{
		id: 0,
		img: box_img_1,
		title: 'gold',
		descr: 'community rewards',
		color: '#FFD600',
	},
	{
		id: 1,
		img: box_img_2,
		title: 'Platinum',
		descr: 'mining boost',
		color: '#00A3FF',
	},
	{
		id: 2,
		img: box_img_3,
		title: 'Diamond',
		descr: 'Guaranteed airdrop',
		color: '#00D1FF',
	},
]

export default feature_box_items;