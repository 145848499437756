import React, { useEffect, useState } from 'react'

import group_box_img from '../../../assets/img_4.png'
import neon1 from '../../../assets/neon_img_1.svg'
import detectBrowser from '../../../../../utils/detectBrowser';

const BoxImage = () => {
	const [browser, setBrowser] = useState<string>("");

	useEffect(() => {
		setBrowser(detectBrowser())
	}, [])

	return (
		<div className='w-[220px] my_md:w-[480px] lg:w-[280px] h-full -translate-x-1/2 my_md:translate-x-0 -mr-32 my_md:-mr-12 lg:mr-0 '>
			<div className="-ml-6 lg:ml-0 h-full w-full relative my_md:rotate-[12deg] ">
				{browser !== 'Safari' && <div className="size-[580px] bg-[#FFD600] blur-[145px] opacity-15"></div>}
				<img src={neon1} alt="" className="absolute top-0 bottom-0 left-1/2 -translate-x-1/2 h-full z-10" />
				<img src={group_box_img} alt="" className=" lg:max-h-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20" />
			</div>
		</div>
	)
}
export default BoxImage