import React from 'react'
import VerticalLine from '../../../components/VerticalLine'
import SectionHeading from '../../../components/SectionHeading'
import GradBorderContainer from '../../../components/GradientBorderBlock'
import lorebook_items from '../../../_constants/lorebook_items'
import { LoreBookDiamondButton } from '../../../components/ClipButton'

const Lorebook = () => {
	return (
		<>
			<VerticalLine color='linear-gradient(to bottom, #FFF600 0%, #994BFF 100%)' className='h-40 relative' />
			<SectionHeading hexagonColor='#994BFF' className='relative'>
				Lorebook
			</SectionHeading>
			<VerticalLine className='h-5 my_md:h-10 relative' color='#994BFF' />
			<GradBorderContainer className='py-5 my_md:py-10  relative flex flex-col gap-5 my_md:gap-8 relative items-center justify-center' gradColor='#994BFF'>
				<div className="absolute -top-[2px] h-[2px] z-30 w-[calc(100%-42px)] bg-[#994BFF]"></div>
				<div className="flex items-center justify-center gap-6 max-w-full overflow-hidden">
					{lorebook_items.map(item => (
						<img src={item.img} key={item.id} alt="" className="w-[200px] my_md:w-[360px]" />
					))}
				</div>
				<p className="px-5 text-grey text-center my_md:text-left text-[20px] my_md:text-2xl leading-normal tracking-[-0.48px] font-medium">
					Envision a Universe where reality's limits are reshaped, cyberpunk and sci–fi merge, and the mysterious SatoshiUniverse Nakamoto transcends Bitcoin's creator
				</p>

				<div className="w-full px-5 flex items-center justify-center">
					<LoreBookDiamondButton containerClassName='w-full my_md:w-auto'>
						READ LOREBOOK
					</LoreBookDiamondButton>
				</div>
			</GradBorderContainer>
		</>
	)
}

export default Lorebook