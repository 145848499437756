import GradBorderContainer from "../../../../components/GradientBorderBlock"
import SectionHeading from "../../../../components/SectionHeading"
import VerticalLine from "../../../../components/VerticalLine"
import { AppleStoreBtn, GooglePlayBtn } from "../../../../components/ClipButton"

import game_icon_1 from '../../assets/game/icon1.svg'
import GridBlock from "./GridBlock"

import coin_img from '../../assets/coin/coin_group.png'
import partner_img_1 from '../../../2.Partners/assets/img_3.svg'
import partner_img_2 from '../../../2.Partners/assets/img_2.svg'
import partner_img_3 from '../../../2.Partners/assets/img_1.svg'
import partner_img_4 from '../../../2.Partners/assets/img_9.svg'
import phone_img from '../../assets/game/phone1.png'

const GameSection = () => {
	const partners = [
		partner_img_1,
		partner_img_2,
		partner_img_3,
		partner_img_4,
	]

	return (
		<section id="games" className="relative z-40">
			<VerticalLine className='h-40 relative' color='linear-gradient(to bottom, #FFD600 0%, #994BFF 100%)' />

			<SectionHeading hexagonColor='#994BFF' className=''>
				Enjoy Games & Earn <span className="text-gold">GOLD</span>
			</SectionHeading>
			<VerticalLine className='h-10 relative' color='#994BFF' />

			<GradBorderContainer
				direction='vertical' gradColor='#994BFF'
				className='p-5 my_md:p-10 flex flex-col-reverse my_md:flex-row gap-5 my_md:gap-10 items-center justify-center relative'
			>
				<img src={coin_img} alt="flying coin" className='absolute top-1/4 right-8 my_md:top-0 my_md:right-0 translate-x-1/2 -translate-y-1/3 w-[160px]' />
				<img src={coin_img} alt="flying coin" className='absolute bottom-1/2 -left-12 my_md:bottom-0 my_md:-left-24 rotate-180 scale-y-[-1] w-[180px] translate-y-1/4' />

				<div className="flex-1 flex flex-col gap-5 my_md:gap-8">
					<div className="hidden my_md:flex items-center justify-start gap-8">
						<img src={game_icon_1} alt="" className="" />
						<h3 className="text-5xl leading-none font-chakra font-bold">Runner <br /> 2060</h3>
					</div>

					<div className="">
						<p className="text-grey text-center my_md:text-left text-[20px] my_md:text-[32px] leading-[150%] font-medium tracking-[-0.64px] font-chakra ">
							A fast-paced, cyberpunk runner where you outmaneuver HiveNet, collect coins, and claim rewards
						</p>

						<div className="grid grid-cols-2 gap-4 mt-6">
							<GridBlock>
								<h4 className="font-bold font-chakra text-[15px] text-center my_md:text-left my_md:text-2xl leading-[128%]">60M+</h4>
								<p className="text-grey font-medium leading-[150%] tracking-[-0.32px] text-center my_md:text-left text-xs my_md:text-base">GOLD mined</p>
							</GridBlock>
							<GridBlock>
								<h4 className="font-bold font-chakra text-[15px] text-center my_md:text-left my_md:text-2xl leading-[128%]">1.5M+</h4>
								<p className="text-grey font-medium leading-[150%] tracking-[-0.32px] text-center my_md:text-left text-xs my_md:text-base">Badges minted</p>
							</GridBlock>
							<GridBlock className="col-span-2">
								<div className="flex my_md:block w-auto lg:w-2/3 xl:w-auto xl:flex justify-center my_md:justify-normal xl:mb-2 leading-[40px] xl:leading-normal">
									{partners.map((partner, index) => (
										<div className="inline-flex items-center justify-center group" key={index}>
											<img src={partner} alt="" className="h-4 my_md:h-6 " />
											{index !== partners.length - 1 && <div className="w-[2px] group-even:block group-even:my_md:hidden group-even:xl:block h-4 bg-[#0B365C] mx-2 my_md:mx-4" />}
										</div>
									))}
								</div>
								<p className="text-grey text-xs my_md:text-base font-medium leading-[150%] tracking-[-0.32px] mt-1 my_md:mt-0 text-center my_md:text-left">
									Events in partnership
								</p>
							</GridBlock>
						</div>
					</div>

					<div className="flex justify-center items-center gap-2 my_md:gap-6 w-full relative z-40">
						<AppleStoreBtn containerClassName="flex-1" className="flex items-center justify-center" />
						<GooglePlayBtn containerClassName="flex-1" className="flex items-center justify-center" />
					</div>
				</div>

				<div className="flex-1">
					<div className="flex my_md:hidden items-center justify-center gap-5 mb-5">
						<img src={game_icon_1} alt="" className="size-20" />
						<h3 className="text-[25px] leading-none font-chakra font-bold">Runner <br /> 2060</h3>
					</div>
					<img src={phone_img} alt="" className="w-[calc(150%)] -ml-[25%] lg:w-[calc(100%+100px)]  lg:-ml-12 max-w-none xl:max-w-full xl:ml-0 xl:w-full " />
				</div>
			</GradBorderContainer>
		</section>
	)
}

export default GameSection