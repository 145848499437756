import React from 'react'
import VerticalLine from '../../../components/VerticalLine'
import SectionHeading from '../../../components/SectionHeading'
import GradBorderContainer from '../../../components/GradientBorderBlock'
import ClipButtonBase from '../../../components/ClipButton'

import img from '../assets/img.png'
import bg_img from '../assets/bg.svg'

const Artbook = () => {
	return (
		<div id='artbook'>
			<VerticalLine color='linear-gradient(to bottom, #EE4925 0%, #FFF600 100%)' className='h-40' />
			<SectionHeading hexagonColor='#FFF600' >
				Artbook
			</SectionHeading>
			<div className="flex h-28 my_md:h-36 xl:h-28 items-center ">
				<VerticalLine color='#FFF600' className='h-full' />
				<p className="text-grey ml-5 text-[15px] my_md:text-2xl leading-[150%] tracking-[-0.48px] font-medium" >
					Unveil the neon-drenched dystopia of Satoshi Universe, where every page tells a tale of rebellion, survival, and the relentless pulse of the digital frontier
				</p>
			</div>
			<GradBorderContainer gradColor='#FFF600' direction='horizontal' className='relative py-10 xl:px-10 flex flex-col items-center justify-center gap-4' >
				<div className="relative">
					<div className="hidden xl:block w-screen abs-center mt-56">
						<img src={bg_img} alt="" className="" />
					</div>

					<div className='relativeblock my_sm:hidden'>
						<div className="w-screen overflow-x-scroll my_md:relative z-20">
							<div className="h-[300px] w-[597px] my_md:w-auto my_md:h-auto overflow-visible relative my_md:static">
								<img src={img} alt="" className="abs-center relative h-full w-full z-20" />
							</div>
						</div>
					<div className="">
					</div>
					<img src={img} alt="" className="abs-center blur-[60px]" />
					</div>
					
				
					<div className="hidden my_sm:block h-[300px] w-[597px] my_md:w-auto my_md:h-auto overflow-visible relative my_md:static">
						<img src={img} alt="" className="abs-center blur-[60px]" />
						<img src={img} alt="" className="relative h-full w-full" />
					</div>
				</div>

				<ClipButtonBase className='px-8 py-2 flex flex-col items-center justify-center' containerClassName='w-full my_md:w-auto'>
					<span className="font-bold leading-[20px] tracking-[0.1px] text-grey uppercase">[ Buy artbook ]</span>
					<span className="font-bold leading-[150%] tracking-[0.1px] text-gold uppercase">soon</span>
				</ClipButtonBase>

				<div 
				style={{
					background: 'linear-gradient(to right, rgba(2, 15, 27,0) 0%, #FFF600 2%, #FFF600 98%, rgba(2, 15, 27,0) 100%)'
				}}
				className="h-[2px] w-full absolute -bottom-[2px] hidden xl:block"></div>
			</GradBorderContainer>
		</div>
	)
}

export default Artbook

