import React from 'react'

const MarqueeBlock = ({children}:{children?:React.ReactNode}) => {
	return (
		<div className='w-[225px] h-[120px] mx-2 rounded-2xl bg-indigo flex items-center justify-center'>
			{children}
		</div>
	)
}

export default MarqueeBlock