import React from 'react'
import VerticalLine from '../../../components/VerticalLine'
import SectionHeading from '../../../components/SectionHeading'

import img from '../assets/soundtrack_img.png'
import { AppleMusicBtn, SpotifyBtn, YTMusicBtn } from '../../../components/ClipButton'

import bg_img from './assets/bg.png'

const Soundtrack = () => {
	return (
		<div id='soundtrack' className='relative'>
			<div className="hidden xl:flex w-screen top-full abs-center mt-56 items-end justify-end">
				<img src={bg_img} alt="" className="" />
			</div>

			<VerticalLine color='linear-gradient(to bottom, #994BFF 0%, #00FFD3 100%)' className='h-40 relative' />
			<SectionHeading hexagonColor='#00FFD3' className='capitalize'>
				Listen to our <span className="text-gold">soundtrack</span>
			</SectionHeading>
			<VerticalLine className='h-5 my_md:h-10' color='#00FFD3' />

			<div
				style={{
					background: 'linear-gradient(to right, rgba(0, 255, 211, .32) 0%, rgba(0, 255, 211, .0) 100%)',
				}}
				className="border-l-[2px] flex flex-col my_md:flex-row items-center justify-center gap-0 my_md:gap-10 my_md:pr-8 border-[#00FFD3]  ml-6 relative z-10 overflow-hidden"
			>
				<div className="h-full p-5 my_md:p-6 flex-shrink-0 my_sm:-mr-10 lg:mr-0">
					<img src={img} alt="" className="h-auto my_md:h-[280px] lg:h-[423px] " />
				</div>

				<div className="py-0 my_md:py-[60px] lg:py-0 flex flex-col items-center justify-start gap-5 my_md:gap-8 px-5 pb-5">
					<p className=" text-grey text-center my_md:text-left text-[20px] my_md:text-[32px] leading-normal tracking-[-0.64px] font-medium">
						Escape the digital abyss with haunting rhythms that echo the chaos of a world on the brink
					</p>
					<div className="grid grid-cols-2 w-full gap-[10px] my_md:gap-6">
						<SpotifyBtn />
						<AppleMusicBtn />
						<YTMusicBtn className='' containerClassName='col-span-2' />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Soundtrack