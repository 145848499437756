import React from 'react'
import cn from '../../utils/cn'
import Hexagon from '../Hexagon'

const SectionHeading = ({
	className = '',
	children,
	subheadingText,
	hexagonColor,
}: {
	className?: string
	children?: React.ReactNode
	subheadingText?: string
	hexagonColor?: string
}) => {
	return (
		// <h2 className={cn('text-5xl leading-[128%] font-chakra font-bold text-white', className)}>
		// 	{children}
		// </h2>

		<div className={cn("flex gap-5 ")}>
			<Hexagon fillColor={hexagonColor}  />
			<div className="flex flex-col items-start justify-start">
				{subheadingText && <h3 className="text-grey text-[20px] my_md:text-[32px] leading-[128%] font-medium font-chakra">{subheadingText}</h3>}
				<h2 className={cn('text-[35px] my_md:text-5xl leading-[128%] font-chakra font-bold text-white', className)}>
					{children}
				</h2>
			</div>
		</div>
	)
}

export default SectionHeading