// What exactly is the Satoshi Universe? Could you provide some background lore?
// What is available in the Satoshi Universe?
// Where can I find all the links for your project?

// Satoshi Universe is an adventure across a vast ecosystem with diverse products. Everything is out project is connected lore–wise to each other, from comics to the Hex Planet. More info about our lore: https://satoshi–universe.gitbook.io/satoshi–universe–lore
// At that moment we have weekly updating comics, Linea Card NFTs and the Hex Planet & Runner2060 playtest is ongoing: 📷︲official–links. The clans are open to join and do not forget to check our soundtrack – https://band.link/mJ20w
// Yes, we have a list of the guides here – https://su0–1.gitbook.io/hexplanet

// const FAQ_items = [
// 	{
// 		id: 0,
// 		question: 'What exactly is the Satoshi Universe? Could you provide some background lore?',
// 		answer: 'Satoshi Universe is an adventure across a vast ecosystem with diverse products. Everything is out project is connected lore–wise to each other, from comics to the Hex Planet. More info about our lore: ',
// 		end_link: 'https://satoshi–universe.gitbook.io/satoshi–universe–lore', 
// 	},
// 	{
// 		id: 1,
// 		question: 'What is available in the Satoshi Universe?',
// 		answer: 'At that moment we have weekly updating comics, Linea Card NFTs and the Hex Planet & Runner2060 playtest is ongoing: 📷︲official–links. The clans are open to join and do not forget to check our soundtrack – ',
// 		end_link: 'https://band.link/mJ20w',
// 	},
// 	{
// 		id: 2,
// 		question: 'Where can I find all the links for your project?',
// 		answer: 'Yes, we have a list of the guides here – ',
// 		end_link: 'https://su0–1.gitbook.io/hexplanet',
// 	},

// 	{
// 		id: 3,
// 		question: 'What exactly is the Satoshi Universe? Could you provide some background lore?',
// 		answer: 'Satoshi Universe is an adventure across a vast ecosystem with diverse products. Everything is out project is connected lore–wise to each other, from comics to the Hex Planet. More info about our lore: ',
// 		end_link: 'https://satoshi–universe.gitbook.io/satoshi–universe–lore', 
// 	},
// 	{
// 		id: 4,
// 		question: 'What is available in the Satoshi Universe?',
// 		answer: 'At that moment we have weekly updating comics, Linea Card NFTs and the Hex Planet & Runner2060 playtest is ongoing: 📷︲official–links. The clans are open to join and do not forget to check our soundtrack – ',
// 		end_link: 'https://band.link/mJ20w',
// 	},
// 	{
// 		id: 5,
// 		question: 'Where can I find all the links for your project?',
// 		answer: 'Yes, we have a list of the guides here – ',
// 		end_link: 'https://su0–1.gitbook.io/hexplanet',
// 	},

// ]

const FAQ_items = [
	{
		id: 0,
		question: 'What is the Satoshi Universe Ecosystem?',
		answer: 'The Satoshi Universe Ecosystem is a blockchain-based entertainment ecosystem that integrates gamification with DeFi, offering users rewarding experiences across multiple games. It allows you to stake tokens, earn in-game currency (GOLD), and use $LORE tokens within a unified ecosystem. ',
		end_link: '', 
	},
	{
		id: 1,
		question: 'What are $LORE tokens, and how do I use them?',
		answer: ' $LORE tokens are the native tokens of the Satoshi Universe. You can buy, stake, and use them to earn rewards, improve your in-game experience, make in-game purchases, and stay tuned for even more features coming soon!',
		end_link: '',
	},
	{
		id: 2,
		question: 'What are Battle Passes, and how do they work?',
		answer: 'Battle Passes are classed membership passes that offer various benefits, including increased GOLD rewards, exclusive items, and node functionality. Available in four classes - Gold, Platinum, Diamond, and Carbon - each class provides different levels of perks and rewards. All Battle Passes come with node functionality, enabling holders to participate in farming tokens. Additionally, Battle Pass holders receive most of their farmed rewards in tokens immediately at the Token Generation Event (TGE), providing early access to the ecosystem\'s benefits.',
		end_link: '',
	},

	{
		id: 3,
		question: 'How do I purchase a Battle Pass?',
		answer: 'Log into the Telegram mini-app, select your desired pass, connect your TON wallet, and complete the transaction. Your Battle Pass will be added to your account upon successful purchase.',
		end_link: '', 
	},
	{
		id: 4,
		question: 'Can I sell or trade my Battle Pass?',
		answer: 'No, Battle Passes cannot be sold or traded. Once you purchase a Battle Pass, it is tied to your account and provides you with exclusive benefits and rewards within the Satoshi Universe ecosystem.',
		end_link: '',
	},
	{
		id: 5,
		question: 'What is KYC, and why is it required for all Battle Pass classes?',
		answer: 'KYC (Know Your Customer) is required for all Battle Pass classes to ensure regulatory compliance, as each class includes node functionality. Users must verify their identity by submitting necessary documents through a reliable third-party provider before purchasing any Battle Pass. This process helps maintain a secure and compliant ecosystem.',
		end_link: '',
	},
	{
		id: 6,
		question: 'How do I connect my TON wallet to the Satoshi Universe?',
		answer: 'Connect your TON wallet via the Telegram mini-app or the web app by following the provided instructions. Once connected, you can use your wallet for transactions, including purchasing Battle Passes.',
		end_link: '',
	},
]

export default FAQ_items